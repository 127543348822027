* {
  box-sizing: border-box;
  font-family: "Helvetica Neue", Arial, sans-serif;
}

aside {
  border-top: 1px solid gray;
  padding-top: 20px;
}

dl {
  display: grid;
  grid-column-gap: 15px;
  grid-row-gap: 2px;
  grid-template-columns: 2fr 1fr;
  max-width: 300px;
}

dt {
  text-align: right;
}

dd {
  margin: 0;
}

#app {
  display: grid;
  grid-column-gap: 20px;
  grid-gap: 20px;
  grid-row-gap: 10px;
  margin: 20px auto;
  min-width: 500px;
}

@media only screen and (max-width: 999px) {
  #app {
    grid-template-areas:
      "store basket"
      "image name"
      "image options"
      "image buy"
      "reco reco";
    grid-template-columns: 4fr 3fr;
  }
}

@media only screen and (min-width: 1000px) {
  #app {
    grid-template-areas:
      "store basket  reco"
      "image name    reco"
      "image options reco"
      "image buy     reco";
    grid-template-columns: 4fr 3fr 200px;
    width: 1000px;
  }
}

#store {
  font-weight: 400;
  grid-area: store;
  margin-top: 5px;
}

#basket {
  align-self: baseline;
  grid-area: basket;
  justify-self: end;
  margin-top: 11px;
}

#basket .empty,
#basket .filled {
  border-radius: 5px;
  color: white;
  padding: 5px 10px;
}

#basket .empty {
  background-color: gray;
}

#basket .filled {
  background-color: seagreen;
}

#image {
  grid-area: image;
  width: 100%;
}

#image > div {
  padding-top: 100%;
  position: relative;
}

#image img {
  bottom: 0;
  left: 0;
  max-width: 100%;
  position: absolute;
  right: 0;
  top: 0;
}

#name {
  font-weight: 400;
  grid-area: name;
  height: 3em;
}

#name small {
  font-size: 1em;
  font-weight: 200;
}

#options {
  align-self: center;
  display: flex;
  grid-area: options;
}

#options button {
  border: none;
  border-bottom: 2px solid white;
  cursor: pointer;
  display: block;
  margin: 2px;
  outline: none;
  padding: 0;
}

#options button.active,
#options button:hover {
  border-bottom-color: seagreen;
}

#options img {
  display: block;
  max-width: 100%;
}

#buy {
  align-self: center;
  grid-area: buy;
}

#buy button {
  background: none;
  border: 1px solid gray;
  border-radius: 20px;
  cursor: pointer;
  display: block;
  font-size: 20px;
  outline: none;
  padding: 20px;
  width: 100%;
}

#buy button:hover {
  border-color: black;
}

#buy button:active {
  border-color: seagreen;
}

#reco {
  grid-area: reco;
}

@media only screen and (max-width: 999px) {
  #reco {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 20px;
    padding-top: 20px;
  }
}

@media only screen and (min-width: 1000px) {
  #reco {
    justify-content: stretch;
    text-align: center;
    width: 100%;
  }
}

#reco h3 {
  font-weight: 400;
}

#reco img {
  display: inline-block;
  height: 180px;
  width: 180px;
}

#app {
  outline: 3px dashed orangered;
  padding: 15px;
}

.green-recos {
  display: block;
  outline: 3px dashed forestgreen;
  width: 100%;
}

.blue-basket {
  display: block;
  outline: 3px dashed royalblue;
  padding: 5px;
}

.blue-buy {
  display: block;
  outline: 3px dashed royalblue;
  padding: 5px;
}
